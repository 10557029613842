import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import PublicPageWrapper from '../PublicPageWrapper';

const ThankYouPage = ({ text }) => {
  const { t } = useTranslation();

  return (
    <PublicPageWrapper>
      <Box maxWidth="100%" width={550}>
        <Typography
          component="h1"
          variant="h2"
          gutterBottom
        >
          {t(`titles:${text}`)}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t(`descriptions:${text}`)}
        </Typography>
        <Box mt={2}>
          <Button
            color="secondary"
            component={Link}
            to="/app/account"
            variant="contained"
          >
            {t('labels:account')}
          </Button>
        </Box>
      </Box>
    </PublicPageWrapper>
  );
};

ThankYouPage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ThankYouPage;
