import { makeStyles } from '@material-ui/core';

import { isString } from 'lodash';

export default makeStyles((theme) => ({
  logo: {
    height: 95,
    width: 180,
    display: 'block',

    '& img': {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  container: {
    maxWidth: '85vw',
    width: 'max-content',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  photo: ({ photo }) => ({
    backgroundColor: theme.palette.secondary.light,
    backgroundImage: isString(photo)
      ? `url("${String(photo).replace(/\s/gi, '%20')}")`
      : undefined,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundBlendMode: 'multiply',
    minHeight: '55vh',
  }),
}));
