import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Link } from '@material-ui/core';
import { Link as ReachLink } from 'gatsby';
import { useTranslation } from 'q3-ui-locale';
import useSiteMetaData from 'gatsby-theme-q3/src/components/useSiteMetaData';
import useStyle from './styles';

const Copyright = () => {
  const { brand } = useSiteMetaData();

  return brand ? (
    <Box display="inline-block" mx={1}>
      ©{new Date().getFullYear()} {brand}
    </Box>
  ) : null;
};

// eslint-disable-next-line
const TextLink = ({ href, text }) => {
  const { t } = useTranslation('labels');

  return href ? (
    <Box display="inline-block" mx={1}>
      <Link href={href} target="_blank">
        {t(text)}
      </Link>
    </Box>
  ) : null;
};

const PublicPageWrapper = ({ children }) => {
  const {
    brand,
    cancellation,
    logo,
    terms,
    privacy,
    photo,
  } = useSiteMetaData();
  const cls = useStyle({
    photo,
  });

  return (
    <Box
      alignItems="center"
      component="article"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Box
        component="header"
        mt="2vh"
        mb={1}
        textAlign="center"
      >
        <ReachLink to="/" className={cls.logo}>
          <img alt={brand} src={logo} />
        </ReachLink>
      </Box>
      <Paper className={cls.container}>
        <Box height="100%" width="100%" p={2}>
          {children}
        </Box>
      </Paper>
      <Box
        maxWidth="75vw"
        component="footer"
        mt={2}
        textAlign="center"
      >
        <small>
          <Copyright />
          <TextLink
            href={terms}
            text="termsAndConditions"
          />
          <TextLink href={privacy} text="privacyPolicy" />
          <TextLink
            href={cancellation}
            text="cancellationPolicy"
          />
        </small>
      </Box>
    </Box>
  );
};

PublicPageWrapper.defaultProps = {
  children: null,
};

PublicPageWrapper.propTypes = {
  children: PropTypes.node,
};

export default PublicPageWrapper;
